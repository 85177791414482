import React from 'react';
import { Col, Heading, Row, Text } from '@fast-ai/ui-components';
import LinkButton from '@fast-ai/ui/LinkButton';

import { DefaultLayout } from '../layout';
import { RichFormattedMessage, useTranslateMessage } from '../intl';
import m from '../intl/messages/pageError';
import { P, Section, Seo } from '../ui';

const ErrorPage = props => {
	const translate = useTranslateMessage();
	return (
		<DefaultLayout {...props}>
			<Seo title={translate(m.title)} />

			<Section>
				<Row
					sx={{
						alignItems: 'center',
						flexWrap: 'wrap',
						textAlign: ['center', 'center', 'left'],
						py: [5, 5, 6],
						position: 'relative',
						zIndex: 1,
					}}
				>
					<Col span={[12, 12, 6]}>
						<Heading
							sx={{
								fontSize: [6, 7, 8, 8],
								lineHeight: 1,
								mb: [2, 2, 3, 3],
								maxWidth: '40rem',
							}}
						>
							<RichFormattedMessage {...m.heading} />
						</Heading>
						<Text
							sx={{
								maxWidth: '38rem',
								mb: [2, 2, 4, 4],
							}}
						>
							<RichFormattedMessage {...m.urlNotFound} />
						</Text>
						<P>
							<LinkButton variant="primary" to="/" sx={{ ml: 2 }}>
								Zoe.ai Homepage
							</LinkButton>
						</P>
					</Col>
				</Row>
			</Section>
		</DefaultLayout>
	);
};

export default ErrorPage;
