import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	title: {
		id: 'errorPage.title',
		defaultMessage: 'Error 404 - Not found',
	},
	heading: {
		id: 'errorPage.heading',
		defaultMessage: 'Error <strong>404</strong>.',
	},
	urlNotFound: {
		id: 'errorPage.urlNotFound',
		defaultMessage: 'The requested URL was not found.',
	},
});
